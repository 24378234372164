// src/VideoRecorder.js
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

toast.configure({
  autoClose: 2000,
  pauseOnFocusLoss: false,
});

const mimeType = 'video/webm; codecs="opus,vp8"';

const VideoRecorder = ({ selectedAnswer, setSelectedAnswer, questionIndex, data }) => {
  const [recordedVideo, setRecordedVideo] = useState(selectedAnswer[questionIndex]);
  const [permission, setPermission] = useState(false);
  const [seconds, setSeconds ] =  useState(data?.answer_duration*60);
  const videoRef = useRef(null);
  const mediaRecorder = useRef(null);


  const startRecording = async () => {
    setRecordedVideo(null);
    if (!("MediaRecorder" in window)) {
      return alert("The MediaRecorder API is not supported in your browser.");
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      setPermission(true);
      videoRef.current.srcObject = stream;
      mediaRecorder.current = new MediaRecorder(stream, { mimeType });
      mediaRecorder.current.start();
      const localVideoChunks = [];
      mediaRecorder.current.ondataavailable = (event) => {
        if (event.data.size > 0) localVideoChunks.push(event.data);
      };

      mediaRecorder.current.onstop = () => {
        const videoBlob = new Blob(localVideoChunks, { type: mimeType });
        const videoUrl = URL.createObjectURL(videoBlob);
        setRecordedVideo(videoUrl);
        setSelectedAnswer(prev => {
          const updatedAnswers = [...prev];
          updatedAnswers[questionIndex] = videoUrl;
          return updatedAnswers;
        });
      };
    } catch (error) {
      toast.error("Please give access to camera and audio to start the test");
      console.error(error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
      setPermission(false);
      setSeconds(data?.answer_duration*60)
    } else {
      console.warn("MediaRecorder is not initialized or has already been stopped.");
    }
  };

  const resetRecording = () => {
    setRecordedVideo(null);
    setPermission(false);
    videoRef.current.srcObject = null;
    videoRef.current.src = '';
    setSeconds(data?.answer_duration*60)
    setSelectedAnswer(prev => {
      const updatedAnswers = [...prev];
      updatedAnswers[questionIndex] = null;
      return updatedAnswers;
    });
  };

  useEffect(() => {
    if (recordedVideo) {
      videoRef.current.srcObject = null;
      videoRef.current.src = recordedVideo;
    }
  }, [recordedVideo]);



  useEffect(()=>{
    if(permission){
      let myInterval = setInterval(() => {
        if (seconds > 0) {
            setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          stopRecording()
        } 
    }, 1000)
    return ()=> {
        clearInterval(myInterval);
      };
    }   
    },[permission,seconds]);

  return (
    <div>
      <div style={{ width: '100%', maxWidth: '450px', margin: '0 ' }}>
      <video
        ref={videoRef}
        autoPlay={!recordedVideo}
        muted
        controls={permission ? false :true}
        style={{ width: '100%', height: 'auto' }}
      />
      </div>
      <div className="d-flex" id="buttonId">
        <button
          onClick={startRecording}
          type="button"
          className="btn btn-primary"
          id="customButton"
          disabled={permission||recordedVideo}
        >
          {permission ? seconds +'sec' : `Start Recording (Max ${data?.answer_duration*60}sec)`}
        </button>
        <button
          type="button"
          className="btn btn-primary"
          id="customButton"
          onClick={stopRecording}
          disabled={!permission}
        >
          Stop Recording
        </button>
        <button
          type="button"
          className="btn btn-primary"
          id="customButton"
          onClick={resetRecording}
          disabled={recordedVideo? false :true}>
          Reset Recording
        </button>
      </div>
    </div>
  );
};

export default VideoRecorder;
